import React from 'react';
import { useSelector } from 'react-redux';
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import { Trans, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import type { IGroup } from 'store/groups/types';
import { selectDashboardUrl, selectIsSiteAdmin } from 'store/selectors';
import { useFileUploader } from 'common/hooks';

import { Hide } from 'wui/Hide';
import { Box } from 'wui/Box';
import { Typography } from 'wui/Typography';
import { TextField } from 'wui/TextField';
import { TextButton } from 'wui/TextButton';
import { Spinner } from 'wui/Spinner';
import { EmptyState } from 'wui/EmptyState';

import { GroupPrivacySelect } from '../GroupPrivacySelect';

import { useRandomCoverImage } from './CoverImageGenerator';
import { EDIT_GROUP_FORM_TITLE_INPUT } from './dataHooks';
import { ImageViewer, EditableImage, isFile } from 'wui/ImageViewer';

export interface IEditableGroup extends Pick<IGroup, 'name' | 'privacyStatus'> {
  coverImage?: EditableImage;
}

export interface IProps
  extends React.ComponentProps<typeof Formik<IEditableGroup>> {
  ['data-hook']?: string;
  autoGenerateImage?: boolean;
  onBusyStateChange?(busy: boolean): void;
}

export function EditGroupForm(props: IProps) {
  const {
    autoGenerateImage,
    onBusyStateChange,
    onSubmit,
    initialValues,
    'data-hook': dataHook,
    ...rest
  } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const uploader = useFileUploader();
  const cover = useRandomCoverImage();

  const isSiteAdmin = useSelector(selectIsSiteAdmin);
  const dashboardUrl = useSelector(selectDashboardUrl);
  const defaultImage = autoGenerateImage ? cover.image : undefined;

  if (cover.isLoading && autoGenerateImage) {
    return <EmptyState variant="section" title={<Spinner />} />;
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validate={handleValidate}
      initialValues={{
        ...initialValues,
        coverImage: initialValues.coverImage || defaultImage,
      }}
      {...rest}
    >
      {(form) => (
        <Box direction="vertical" gap="SP4" data-hook={dataHook}>
          <ImageViewer
            {...form.getFieldProps('coverImage')}
            label={t('groups-web.group.settings.details.upload_image')}
            data-hook="cover-image-editor"
            onChange={(coverImage) => {
              form.setFieldValue('coverImage', coverImage);
              form.setFieldTouched('coverImage', true);
            }}
          />
          <TextField
            required
            maxLength={50}
            errorMessage={form.errors.name}
            error={!!form.errors.name && form.touched.name}
            label={t('groups-web.modals.create-groups.groupName')}
            placeholder={t(
              'groups-web.modals.create-groups.groupName.placeholder',
            )}
            data-hook={EDIT_GROUP_FORM_TITLE_INPUT}
            {...form.getFieldProps('name')}
          />
          <GroupPrivacySelect
            {...form.getFieldProps('privacyStatus')}
            onChange={(privacy) => form.setFieldValue('privacyStatus', privacy)}
          />
          <Hide if={isMobile || !isSiteAdmin}>
            <Typography as="p">
              <div>
                <Trans
                  i18nKey="groups-web.create-group-dialog.manage-dashboard"
                  components={[
                    <TextButton
                      key={0}
                      as="a"
                      variant="link"
                      href={dashboardUrl}
                    />,
                  ]}
                />
              </div>
            </Typography>
          </Hide>
        </Box>
      )}
    </Formik>
  );

  async function handleSubmit(
    values: IEditableGroup,
    helpers: FormikHelpers<IEditableGroup>,
  ) {
    if (!props.onSubmit) {
      return;
    }

    if (!isFile(values.coverImage)) {
      props.onSubmit(values, helpers);
      return;
    }

    try {
      onBusyStateChange?.(true);

      const {
        width,
        height,
        file_name: mediaId,
      } = await uploader.upload(values.coverImage);

      props.onSubmit(
        {
          name: values.name,
          privacyStatus: values.privacyStatus,
          coverImage: {
            image: {
              mediaId,
              height,
              width,
              preset: values.coverImage === defaultImage,
            },
          },
        },
        helpers,
      );
    } catch {
      props.onSubmit(values, helpers);
    } finally {
      onBusyStateChange?.(false);
    }
  }

  function handleValidate(values: IEditableGroup) {
    const errors: FormikErrors<IEditableGroup> = {};

    if (!values.name?.trim()) {
      errors.name = t('groups-web.create-group.error.name.required');
    }

    return errors;
  }
}

EditGroupForm.displayName = 'EditGroupForm';
