import React from 'react';
import cls from 'classnames';

import FocusTrap from 'focus-trap-react';
import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';

import { Portal } from '../Portal';
import { Backdrop } from '../Backdrop';

import { useScrollBlock } from './useScrollBlock';

import classes from './Modal.scss';

interface IModalProps extends TPAComponentProps {
  isOpen?: boolean;
  children: React.ReactElement;

  backdrop?: boolean;
  hideBackdrop?: boolean;
  keepMounted?: boolean;
  focusTrap?: boolean;
  disableEscapeKeyDown?: boolean;

  onClose(event?: React.SyntheticEvent): void;
  onClick?(event: React.MouseEvent): void;
  onKeyDown?(event: React.SyntheticEvent): void;
}

export function Modal(props: IModalProps) {
  const {
    children,
    hideBackdrop,
    backdrop = true,
    className,
    focusTrap = false,
    isOpen,
    keepMounted,
    onClick,
  } = props;

  const [exited, setExited] = React.useState(true);

  const hasTransition = !!children.props.hasOwnProperty('in');

  useScrollBlock(isOpen);

  const childProps: {
    appear?: boolean;
    tabIndex?: string;
    onEnter?(): void;
    onExited?(): void;
  } = {};

  if (children.props.tabIndex === undefined) {
    childProps.tabIndex = '-1';
  }

  if (hasTransition) {
    childProps.appear = true;
    childProps.onEnter = handleEnter;
    childProps.onExited = handleExited;
  }

  if (!keepMounted && !isOpen && (!hasTransition || exited)) {
    return null;
  }

  return (
    <Portal>
      <FocusTrap
        active={!exited}
        paused={exited || !focusTrap}
        focusTrapOptions={{
          allowOutsideClick: true,
          escapeDeactivates: false,
        }}
      >
        <div
          onClick={onClick}
          data-hook={props['data-hook']}
          onKeyDown={handleKeyDown}
          className={cls(classes.root, className, {
            [classes.exited]: exited,
          })}
        >
          {!backdrop ? null : (
            <Backdrop
              isOpen={isOpen}
              data-hook="modal-backdrop"
              transparent={hideBackdrop}
              onClick={handleBackdropClick}
            />
          )}
          {React.cloneElement(children, childProps)}
        </div>
      </FocusTrap>
    </Portal>
  );

  function handleKeyDown(event: React.KeyboardEvent) {
    props.onKeyDown?.(event);

    if (event.key !== 'Escape') {
      return;
    }

    if (!props.disableEscapeKeyDown) {
      event.stopPropagation();

      props.onClose(event);
    }
  }

  function handleEnter() {
    setExited(false);

    children.props.onEnter?.();
  }

  function handleExited() {
    setExited(true);

    children.props.onExited?.();
  }

  function handleBackdropClick(event: React.MouseEvent) {
    props.onClose(event);
  }
}

Modal.displayName = 'wui/Modal';
