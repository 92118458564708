import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { SwitchTransition } from 'react-transition-group';

import {
  Camera as CameraIcon,
  Delete as DeleteIcon,
  Replace as ReplaceIcon,
} from '@wix/wix-ui-icons-common/on-stage';

import { Button } from 'wui/Button';
import { Fade } from 'wui/Fade';
import { Media } from 'wui/Media';
import { ButtonGroup } from 'wui/ButtonGroup';

import { getPreview } from './helpers';
import { type EditableImage } from './types';

import classes from './ImageViewer.scss';
import { IconButton, IconButtonThemes } from 'wui/IconButton';
import { TPAComponentProps } from '../types';

interface IProps extends TPAComponentProps {
  wired?: boolean;
  value: EditableImage;
  label?: string;
  onChange(value?: EditableImage): void;
}

export function ImageViewer({
  wired,
  value,
  label,
  onChange,
  ...rest
}: IProps) {
  const { isMobile } = useEnvironment();

  const $input = React.useRef<HTMLInputElement>(null);

  const [media, setMedia] = React.useState(getPreview(value));

  return (
    <div
      className={cls(classes.root, {
        [classes.mobile]: isMobile,
        [classes.wired]: wired,
        [classes.hasImage]: !!media,
      })}
      data-empty={!media}
      {...rest}
    >
      <SwitchTransition>
        {(() => {
          if (!media) {
            return (
              <Fade key="empty">
                <Button
                  outlined
                  variant={wired ? 'primary' : 'basic'}
                  data-hook="upload-image-button"
                  onClick={handleUploadClick}
                  prefixIcon={<CameraIcon />}
                  size={isMobile ? 'tiny' : 'medium'}
                >
                  {label}
                </Button>
              </Fade>
            );
          }

          return (
            <Fade key={media}>
              <>
                <Media
                  fluid
                  src={media as string}
                  className={classes.image}
                  resize="cover"
                />

                <ButtonGroup
                  className={classes.actions}
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                >
                  <IconButton
                    theme={IconButtonThemes.Box}
                    icon={<ReplaceIcon />}
                    onClick={handleUploadClick}
                  />
                  <IconButton
                    theme={IconButtonThemes.Box}
                    icon={<DeleteIcon />}
                    onClick={handleDeleteClick}
                  />
                </ButtonGroup>
              </>
            </Fade>
          );
        })()}
      </SwitchTransition>

      <input
        hidden
        accept="image/*"
        data-hook="upload-input"
        type="file"
        ref={$input}
        onChange={handleFileInputChange}
      />
    </div>
  );

  function handleDeleteClick() {
    onChange({});
    setMedia(undefined);
  }

  function handleUploadClick() {
    $input.current?.click();
  }

  function handleFileInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const [file] = Array.from(event.target.files!);

    if (media) {
      URL.revokeObjectURL(media);
    }

    setMedia(URL.createObjectURL(file));

    event.target.value = null as any;
    onChange(file);
  }
}

ImageViewer.displayName = 'wui/ImageViewer';
